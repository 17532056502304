<script lang="ts" setup  >
    defineProps({
        error: Object
    })
    const handleError = () => clearError({ redirect: '/' })
</script>
<template>
    <div>
        <p>{{ error.statusCode }}</p>
        <p>Error</p>
        <p>{{ error.message }}</p>
        <button @click="handleError">Go Home...</button>
    </div>
</template>
  
  